import React, { useState, useCallback, useRef, useEffect } from "react"; //import { useNavigate } from "react-router-dom";
import logo from "./Wipeit(4).png"; // Import your logo image
import logout from "./logout.png"; // Import your logo image
import account from "./account.png"; // Import your logo image

import axios from "axios";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const Upload = ({ setAuthenticated }) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadValue, setUploadValue] = useState("Upload");
  const [title, setTitle] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const playvideo = useRef(null);
  const navigate = useNavigate();
  const handleChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setHashtags(selectedOptions);
    }
  };
  useEffect(() => {
    // This code runs after every render
    getUsername();
    // Optional cleanup function
  });
  const getUsername = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      // Fetch the user's profile picture using the authentication token
      const response = await fetch("https://api.wipe.wtf/getUsername", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setUsername(data);

        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogout = async () => {
    try {
      localStorage.removeItem("accessToken");
      setAuthenticated(false);
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };
  const [username, setUsername] = React.useState("");

  const hashtagOptions = [
    { value: "#Meme", label: "#Meme" },
    { value: "#Vlogs", label: "#Vlogs" },
    { value: "#Food", label: "#Food" },
    { value: "#Gaming", label: "#Gaming" },
    { value: "#Travel", label: "#Travel" },
    { value: "#Sport", label: "#Sport" },
    { value: "#DIY", label: "#DIY" },
    { value: "#Animals", label: "#Animals" },
    { value: "#Tech", label: "#Tech" },
    { value: "#Fashion", label: "#Fashion" },
    { value: "#Music", label: "#Music" },
    { value: "#Art", label: "#Art" },
    { value: "#Science", label: "#Science" },
    { value: "#Fitness", label: "#Fitness" },
    { value: "#Politics", label: "#Politics" },
  ];

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoSrc(videoUrl);
      setVideoFile(file);
    }
  }, []);

  const handleUpload = async () => {
    if (!videoFile) {
      alert("No video selected! Please select a video before uploading.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("video", videoFile);
    //formData.append("categories", JSON.stringify(hashtags));
    formData.append("categories", JSON.stringify(hashtags.map((h) => h.value)));

    const endpoint = isEnabled
      ? "https://api.wipe.wtf/uploadonlyfriendsVideo"
      : "https://api.wipe.wtf/uploadVideo";

    try {
      const accessToken = localStorage.getItem("accessToken");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (event) => {
          if (event.total) {
            const percentage = Math.round((event.loaded * 100) / event.total);
            setUploadValue(percentage + "%");
            console.log(`Upload progress: ${percentage}%`);
          }
        },
      };

      const response = await axios.post(endpoint, formData, config);
      const data = response.data;
      console.log("Response data:", data);

      if (data === "true") {
        if (playvideo.current) playvideo.current.unloadAsync();
        navigate("/success");
        setTitle("");
        setVideoSrc(null);

        setIsLoading(false);
        setModalVisible2(!modalVisible2);
      }
    } catch (error) {
      console.error("Error during upload:", error);
      alert("Upload unsuccessful!");
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#0b0b0b",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          top: "20px",
          left: "30px",
        }}
      />

      <button
        //Logout
        disabled={isLoading}
        onClick={handleLogout}
        style={{
          textAlign: "left",
          color: "white",
          margin: "auto",
          width: "180px",
          backgroundColor: "#66141b",
          padding: "14px",
          borderRadius: "15px",
          backdropFilter: "blur(20px)",
          bottom: 10,
          borderWidth: 0,
          right: 10,
          cursor: "pointer",
          position: "absolute",
          boxShadow: "0px 0px 20px rgba(9, 9, 9, 0.17)",
          display: "flex",
          alignItems: "center",
          gap: "20px", // Optional: Adjust the spacing between image and text
        }}
      >
        <img
          src={logout}
          alt="Logo"
          style={{
            width: "32px",
            height: "25px",

            alignSelf: "center",
          }}
        />
        <span
          style={{
            color: "white",
            textAlign: "left",
            alignSelf: "center",
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          Logout
        </span>
      </button>

      <div
        style={{
          textAlign: "center",
          color: "white",
          margin: "auto",
          width: "300px",
          backgroundColor: "rgba(31, 31, 31, 0.8)",
          padding: "55px",
          paddingTop: "60px",
          paddingBottom: "60px",
          borderRadius: "25px",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 20px rgba(9, 9, 9, 0.17)",
        }}
      >
        {videoSrc ? (
          <div>
            <video style={{ borderRadius: "25px" }} width="100%" muted autoPlay>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <input
              type="text"
              placeholder="Video Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "15px",
                border: "1px solid #ccc",
                marginBottom: "10px",

                marginTop: "20px",
                boxSizing: "border-box",
              }}
            />
            <Select
              isMulti
              options={hashtagOptions}
              value={hashtags}
              onChange={handleChange}
              placeholder="#Hashtags"
              styles={{
                container: (base) => ({
                  ...base,
                  marginTop: "5px",
                  color: "grey",
                  fontSize: 14,
                  textAlign: "left",
                  alignSelf: "flex-start",
                  marginBottom: "10px",
                }),
                control: (base) => ({
                  ...base,
                  padding: "5px",
                  borderRadius: "15px",
                  borderColor: "#ccc",
                }),
              }}
            />
            <button
              onClick={handleUpload}
              style={{
                backgroundColor: "#fff",
                color: "#0c0c0c",
                marginTop: "20px",
                padding: "15px",
                borderRadius: "15px",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {uploadValue}
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <h3>Drag and drop your video</h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./Wipeit(4).png"; // Import your logo image

const Success = ({ setAuthenticated }) => {
  //Login Screen for Stats
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const uploadnav = async () => {
    navigate("/upload");
  };
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#0b0b0b",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          top: "20px",
          left: "30px",
        }}
      />

      <text
        style={{
          textAlign: "center",
          color: "white",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        Video uploaded successfully.
      </text>
      <text
        style={{
          textAlign: "center",
          color: "white",
          fontSize: 18,
          fontWeight: "normal",
        }}
      >
        Thank you ❤️
      </text>
      <button
        onClick={uploadnav}
        style={{
          alignSelf: "center",
          textAlign: "center",
          color: "white",
          border: "none",
          fontWeight: "bold",
          fontSize: 14,
          paddingTop: 20,
          backgroundColor: "#0b0b0b",
          textDecorationLine: "underline",
          cursor: "pointer",
        }}
      >
        Upload more
      </button>
    </div>
  );
};

export default Success;
